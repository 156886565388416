import SentryTagsInterface from '@interface/sentry/sentryTagsInterface';
import * as Sentry from '@sentry/nextjs';

const checkLicense = async (codeLicense: string | string[], route: string) =>{

  const url = process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL;

  try {
    const header = {
      method: 'GET'
    };
    const res: Response = await fetch(`${url}${codeLicense}/valid`, header);
    if (res.ok){
      const licenseData = await res.json();
      const license = licenseData.data.attributes;
      return license;
    }
  } catch (error) {
    const tags: SentryTagsInterface = {
      urlPath: `${route}/checkLicense`,
    };

    Sentry.captureException(error,
      {
        tags
      });

    return;
  }

};

export default checkLicense;
