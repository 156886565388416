
import SentryTagsInterface from '@interface/sentry/sentryTagsInterface';
import * as Sentry from '@sentry/nextjs';
import httpCodes from '@utils/httpCodes';

type ShortLinkHandlerProps = {
  code:string
}
export const shortLinkHandler = async ({
  code
}: ShortLinkHandlerProps) => {
  try {
    const uniqueLink: any = await fetch(
      `${process.env.NEXT_PUBLIC_LINK_HANDLER_URL}/${code}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (uniqueLink.status === httpCodes.ok) {
      const { data } = await uniqueLink.json();
      return data;
    }

    throw (uniqueLink);
  } catch (error) {
    const tags: SentryTagsInterface = {
      owners: 'ShortLinkHandler',
      urlPath: `${process.env.NEXT_PUBLIC_LINK_HANDLER_URL}/${code}`
    };

    Sentry.captureException(error,
      {
        tags
      });
  }
};
