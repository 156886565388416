/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import HeaderIntro from '@components/HeaderIntro';
import Section from '@components/Section';
import useLocalStorage from '@hooks/useLocalStorage';
import * as Sentry from '@sentry/nextjs';
import {
  Box,
  Container,
  Loader,
  Text,
  TextLink as TextLinkDefault,
  Button as ButtonDefault
} from '@src';
import { useRouter } from 'next/router';

import { OfflineContext } from '@context/OfflineContext';
import { ContentInterface } from '@interface/components/Section';
import { LicenseInterface } from '@interface/license';
import SentryTagsInterface from '@interface/sentry/sentryTagsInterface';
import { ThemeInterface } from '@interface/theme';
import checkLicense from '@services/checkLicense';
import { shortLinkHandler } from '@services/shortLinkHandler';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
export interface HomeProps {
  theme?: ThemeInterface;
  url: string;
}

const BoxContent = styled(Box)`
  justify-content: initial;
`;

const Score = styled.p`
  width:350px;
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color:#2C3143;
  line-height: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align:center;

  color: #2C3143;
  margin: 65px 0 40px 0;
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    width:100%;
    margin: 20px 0 40px 0;
    justify-content: center;
  }
`;

const TextContent = styled.p`
  width:510px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  line-height: 22px;
  font-size: 18px;

  text-align:center;
  align-items: center;
  justify-content: center;
  color: #44464D;
  margin-bottom:40px;

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    width:100%;
    font-size: 18px !important;
    margin-bottom: 15px;
    white-space: initial;
    line-height: 26px !important;
  }

  b {
    font-weight: 800;
  }
`;

const TextTerm = styled(Text)`
  display: block;
  width: 510px;
  color: #44464D;
  text-align: center;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 40px;


  @media (max-width: ${({ theme })=>theme.breakpoints.md}px){
    width:100%;
    text-align: center;
  }
`;

const TextLink = styled(TextLinkDefault)`
  color: #F09554;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  margin: 0;
`;

const Button = styled(ButtonDefault)`
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 16px 0 0 0;
  cursot:pointer;

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    min-width: 328px;
  }

`;

const ButtonResult = styled.button`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 16px;
  min-width: 418px;
  max-width: 418px;
  min-height: 56px;
  border-radius: 12px;
  border: 0;
  background-color: #2F2E41;

  cursor: pointer;
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  align-items: center;
  margin: 16px 0 0 0;
  cursot:pointer;
  color: #FFFBF7;

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    min-width: 328px;
  }

`;

const TextFooter = styled.p`
  width:328px;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #929AB2;

  text-align: center;
  justify-content: center;
  margin-top: 18px;
`;

export default function Home(props: HomeProps) {
  const { offline } = useContext(OfflineContext);

  const [loading, setLoading] = useState(true);
  const [initInfo, setInitInfo] = useLocalStorage('initInfo');
  const [license, setLicense] = useLocalStorage('license');
  const [ownerId, setOwnerId] = useLocalStorage('ownerId');
  const [shortLinkOtp, setShortLinkOtp] = useLocalStorage('shortLinkOtp');
  const router = useRouter();

  const handleClick = () => {
    setLoading(true);
    if (shortLinkOtp?.code){
      router.push('/otp');
      return;
    }

    if (router.query.link_code){
      router.push('/invite');
      return;
    }

    router.push({
      pathname: '/invite',
      query: router.query
    });
  };

  const getLicense = async (code: string | string[])=>{

    const license: LicenseInterface = await checkLicense(code, 'invite');
    setLoading(false);
    if (!license) {
      return;
    }

    setLicense({
      code,
      email: license.userId,
      licenseId : license.id,
      practitionerId : license.ownerId
    });

    return;
  };

  const setUniqueLink = (code) =>{
    setOwnerId(code);
    return;
  };

  useEffect(()=>{

    const linkCode = router.query.link_code as string;
    if (linkCode){
      (async (code)=>{
        setLoading(true);
        try {
          const { attributes: shortLink } = await shortLinkHandler({
            code
          });
          //shortLink.metadata.data.code
          setShortLinkOtp({
            otpCode: shortLink.metadata.data.otpCode,
            email: shortLink.metadata.data.userId,
            ownerId: shortLink.metadata.data.ownerId
          });
          setUniqueLink(shortLink.metadata.data.ownerId);
          localStorage.removeItem('license');
          setLoading(false);
        } catch (error) {
          const tags: SentryTagsInterface = {
            owners: 'ShortLinkHandler',
            urlPath: `${process.env.NEXT_PUBLIC_LINK_HANDLER_URL}/${code}`
          };

          Sentry.captureException(error,
            {
              tags
            });
        }
      })(linkCode);
      return;
    }
  }, [router.query]);

  useEffect(() => {
    const code = router.query.metadata_id;
    /*const ownerid = router.query.metadata_external_id;
    if (ownerid){
      setUniqueLink(ownerid);
      localStorage.removeItem('license');
      setTimeout(()=>{
        setLoading(false);
      }, 500);
      return;
    }*/

    if (code){
      getLicense(code);
    }

    setLoading(false);
  });

  const auxContent = (
    <BoxContent
      styleSheet={{
        alignItems: 'center',
        padding: '32px'
      }}>

      <Score>
        Descubra como seu estilo de vida impacta sua Saúde!
      </Score>

      <TextContent>
        Este questionário  é uma ferramenta baseada em evidências científicas que analisa o impacto dos seus hábitos diários na sua saúde física e mental.
        Ao final você poderá  ver sua nota e algumas dicas de como melhorar a sua saúde e bem estar.
      </TextContent>
      <TextContent>
        Tempo médio de resposta:&nbsp;&nbsp;<b> 7min.</b>
      </TextContent>

      <Button
        label='AVANÇAR'
        onClick={() => {
          if (ownerId || license) {
            router.push({
              pathname: '/otp'
            });
            return;
          }
          if (!offline) {
            handleClick();
          }
        } } />
      <ButtonResult
        onClick={() => {
          localStorage.clear();
          if (!offline) {
            setLoading(true);
            setOwnerId('');
            setLicense('');
            setShortLinkOtp({});
            router.push('/otp');
          }
        } } >
          ACESSAR ÚLTIMO RESULTADO
      </ButtonResult>
      <TextTerm>
        Ao começar você declara que está de acordo com os<br />
        <TextLink
          onClick={() => {
            window.open(
              'https://www.serenacare.com.br/termos-e-condicoes',
              '_blank',
              'noopener,noreferrer');
          } }>Termos de Uso </TextLink> e <TextLink
          onClick={() => {
            window.open(
              'https://www.serenacare.com.br/politica-de-privacidade',
              '_blank',
              'noopener,noreferrer');
          } }> Políticas de Privacidade </TextLink>
      </TextTerm>
      {/*<TextFooter>
        Caso já tenho preenchido o Screening e queira acessar seus resultados.
        </TextFooter>*/}
    </BoxContent>
  );

  return (
    <>
      <Container>
        {!loading && auxContent}
        {loading && (
          <Box
            styleSheet={{
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Loader/>
          </Box>
        )}
      </Container>
    </>
  );
}
